import {getDate_forApi} from '../helpers/fonctions'
import {IControl, IResult} from './Models/Models'

const TumiFields : string[] = ["TUMINULLS", "TUMIPLACEHOLDERS", "TUMITYPES", "TUMIDISABLED", "TUMIDROPDOWNS"]


export function parseModel(obj: any):IControl[]{
    const array = [] as IControl[];
    let placeholders: {[key: string]: string} = {};
    let disabled: {[key: string]: string} = {};
    let types: {[key: string]: string} = {};
    let dropDowns : {[key: string]: []} = {};

    for(const prop in obj as any)
    {
        switch(prop)
        {
            case "TUMIPLACEHOLDERS":
                placeholders = obj[prop];
                break;
            
            case "TUMIDISABLED":
                disabled = obj[prop];
                break;
            
            case "TUMITYPES":
                types = obj[prop];
                break;

            case "TUMIDROPDOWNS":
                dropDowns = obj[prop];
                break;
            
            case "TUMINULLS":
                break;

            default:
                if(prop.split('_')[1] != "ID")
                array.push(
                    {
                        name: prop,
                        placeholder: FirstLetterToUpper(prop),
                        styleClass: "form-control " + prop,
                        divClass:"div" + prop,
                        value: obj[prop],
                        disabled: false,
                        type : null,
                        options: null
                    }
                )
                break;
        }
    }

    if(types != null)
        for(const nom in types)
        {
            const nouvelObj = array.find(i => i.name.toLowerCase() == nom.toLowerCase()) as IControl;

            if(nouvelObj)
                nouvelObj.type = types[nom];
        }

    if(placeholders != null)
        for(const nom in placeholders)
        {
            const nouvelObj = array.find(i => i.name.toLowerCase() == nom.toLowerCase()) as IControl;

            if(nouvelObj)
                nouvelObj.placeholder = placeholders[nom];
        }


    if(disabled != null)
        for(const nom in disabled)
        {
            const nouvelObj = array.find(i => i.name.toLowerCase() == nom.toLowerCase()) as IControl;

            if(nouvelObj){
                nouvelObj.styleClass = " TumiDisabled";
                nouvelObj.disabled = true;
            }
        }

    if(dropDowns != null)
        for(const nom in dropDowns)
        {
            dropDowns[nom];
            const nouvelObj = array.find(i => i.name.toLowerCase() == nom.toLowerCase()) as IControl;
            
            if(nouvelObj)
                nouvelObj.options = dropDowns[nom];
        }
    return array;
}

export function GetFromView(viewRefs: any, obj: any, inputs: IControl[]): IResult{
    let types: {[key: string]: string} = {};
    let nulls : string[]|null = null;
    const res : IResult = {errors:[], object: obj}

    for(const prop in res.object)
        if(prop == "TUMITYPES")
            types =  res.object[prop];

    for(const prop in  res.object)
        if(prop == "TUMINULLS")
            nulls =  res.object[prop];

    for(const prop in  res.object)
        if(!TumiFields.includes(prop) && prop.split('_')[1] != "ID")
        {
            if(types[prop.toUpperCase()]){
                if(types[prop.toUpperCase()] == "Date")
                {
                    if(inputs.find(i=>i.name == prop)?.value){
                        const date = inputs.find(i=>i.name == prop)?.value
                        
                        if(date)
                            res.object[prop] = getDate_forApi(new Date(date));
                    }
                    else{
                            res.object[prop] = null;
                    }
                }
                else
                {
                    if(inputs.find(i=>i.name == prop)?.value){
                        res.object[prop] = inputs.find(i=>i.name == prop)?.value;
                    }
                    else{
                        res.object[prop] = null;
                    }
                }
            }
            else{
                res.object[prop] = viewRefs[prop][0].input.value;
            }
        }



    if(nulls != null)
        for(const prop in  res.object)
            if(!TumiFields.includes(prop) && prop.split('_')[1] != "ID")
                if(!nulls.includes(prop))
                    if(! res.object[prop] ||  res.object[prop] == "")
                        res.errors.push({error: "required", control:prop});

    return res;
}

function FirstLetterToUpper(string: string){
    return string.charAt(0).toUpperCase() + string.slice(1);
}