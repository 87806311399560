import { dynamicService } from '../../../DAL/Services/Dynamic/dynamic.service'
import { Commit } from "vuex"

export const dynamicStore = {
    namespaced: true,
    state: () => ({
    }) as any,
    actions: {
        async getDynamic({commit}:{commit: Commit}, path: string): Promise<void>{
            await dynamicService.getDynamic(path)
            .then(
                data => commit('getDynamicSuccess', {data, path}),
                error => commit('getDynamicFailure', {error, path})
            );   
        },
        async setDynamic({commit}:{commit: Commit}, {pathSet, retour}:{pathSet:string, retour: any} ): Promise<void>{
            await dynamicService.setDynamic(pathSet, retour)
            .then(
                data => commit('setDynamicSuccess', {data, pathSet}),
                error => commit('setDynamicFailure', {error, pathSet})
            ); 
        },
    },
    mutations: {
        getDynamicSuccess(state: any, {data, path}:{path:string, data: any}){
            state[path] = data ;
        },
        getDynamicFailure(state: any, {error, path}:{path:string, error: any}) {
            state[path] = error;
        },
        setDynamicSuccess(state: any, {data, path}:{path:string, data: any}){
            state[path] = data ;
        },
        setDynamicFailure(state: any, {error, path}:{path:string, error: any}) {
            state[path] = error;
        }
    }
}