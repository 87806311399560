
import { defineComponent } from "vue";
import Form from "./Form.vue"
import Map from "./Map.vue"
import {IContent} from '../../../BLL/models/Content/content'
import store from "../../../BLL/store";
import {Config} from "../../../DAL/Config";

export default defineComponent({
    name: "Header",
    components:{
      Form,
      Map
    },
    props:['propContent'],
    data:function(){
      return{
        images: [] as  string[],
        image: "" as string,
        id: 0,
        content: null as IContent|null,
        numberOfPics: 0 as number,
        loading: true as boolean
      }
    },
    methods:{
      Scroll(){
        const yOffset = -66; 
        const y = (document.getElementById('FO') as any).getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
      },
      ImageSwap(){
        window.setInterval(this.Swap, 5000);
      },
      Swap(){
        if(this.id < this.images.length - 1)
          this.id++
        else
          this.id = 0;

        this.image = this.images[this.id];       
      },

    },
    watch:{
      images:{
        handler(value){
          if(this.content && value.length == this.numberOfPics){
            this.image = this.images[0];      
            this.ImageSwap();
            this.loading = false;
          }
        },
        deep:true
      },
      async propContent(value){
        this.content = value;

        if(this.content){
          await store.dispatch('dynamicStore/getDynamic','/Content/GetPictures?folderName=' + this.content.CONTENT_PICTURES);
          const pictures = (store.state as any).dynamicStore['/Content/GetPictures?folderName=' + this.content.CONTENT_PICTURES] as string[];


          if(pictures && pictures.length > 0)
          {
            this.numberOfPics = pictures.length;

            pictures.forEach(async (element: string) => {
              await store.dispatch('dynamicStore/getDynamic','/Content/GetFile?fileName=' + element);
              let source = Config.Url() + '/Content/GetFile?fileName=' + element;
              
              const image = new Image();
              
              image.onload = () => {
                this.images.push(source);
              } 

              image.src = source;
            });
          }
          else
          {
            this.loading = false;
          }
        }
      }
    }
});
