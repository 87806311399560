import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopUp = _resolveComponent("PopUp")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_MobileMenu = _resolveComponent("MobileMenu")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Cookies = _resolveComponent("Cookies")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PopUp, {
      ref: "popup",
      propContent: _ctx.content
    }, null, 8, ["propContent"]),
    _createVNode(_component_Menu, {
      onClickOnButton: _ctx.ToggleMenu,
      onToggleMobileMenu: _ctx.ToggleMobileMenu
    }, null, 8, ["onClickOnButton", "onToggleMobileMenu"]),
    _createVNode(_component_MobileMenu, { ref: "mobileMenu" }, null, 512),
    _createVNode(_component_Header, { propContent: _ctx.content }, null, 8, ["propContent"]),
    _createVNode(_component_Cookies)
  ], 64))
}