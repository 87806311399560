
import { defineComponent } from "vue";

export default defineComponent({
    name: "Menu",
    methods:{
      Scroll(id: number){
        var target = null;

        switch(id){
          case 1:
            target = document.getElementById('OO')
            break;

          case 2:
            target = document.getElementById('WS')
            break;
          
          case 3:
            target = document.getElementById('FO')
            break;

          case 4:
            target = document.getElementById('RO')
            break;
        }

        const yOffset = -66; 
        const y = (target as any).getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    Toggle(){
        this.$emit('clickOnButton')
      },
    }
});
