
import {parseModel} from '../ModelParser'
import {GetFromView} from '../ModelParser'

import DynamicComponent from './DynamicComponent.vue'
import Datepicker from '@vuepic/vue-datepicker';
import DynamicSelect from './DynamicSelect.vue'
import DynamicTextArea from './DynamicTextArea.vue'

import '@vuepic/vue-datepicker/dist/main.css';
import { IControl } from '../Models/Models';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'DynamicPage',
  components:{
    DynamicComponent,
    Datepicker,
    DynamicSelect,
    DynamicTextArea
  },
  props:[    
    'objet',
    'validate',
    'pass',
    'name',
    'desc'
  ],
  emits:['action'],
  data: function(){
    return{
      inputs : null as IControl[]|null,
    }
  },
  methods:{
    Submit(){
        if(this.inputs)
        {
          var ob = this.objet;
          var res = GetFromView(this.$refs, ob, this.inputs);
          this.$emit('action', res);
        }
    }
  },
  created(){
    this.inputs = parseModel(this.objet);
  },
  watch:{
    objet(value: any){
      if(value)
        this.inputs = parseModel(value);
    }
  }
});
