import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import SignUp from '../views/SignUp.vue'
import ConfirmMail from '../views/ConfirmMail.vue'
import RetrievePass from '../views/RetrievePass.vue'
import SetNewPass from '../views/SetNewPass.vue'
import LoggedView from '../views/LoggedView.vue'
import Cookies from '../views/Cookies.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/Cookies',
    name: 'Cookies',
    component: Cookies
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/ConfirmMail/:id',
    name: 'ConfirmMail',
    component: ConfirmMail
  },
  {
    path: '/RetrievePass',
    name: 'RetrievePass',
    component: RetrievePass
  },
  {
    path: '/SetNewPass/:id',
    name: 'SetNewPass',
    component: SetNewPass
  },
  {
    path: '/Logged',
    name: 'LoggedView',
    component: LoggedView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const privatePages = ['/ee'];
  const authRequired = privatePages.includes(to.path.toLowerCase());
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/');
  }

  next();
})

export default router
