
import store from "../../BLL/store";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Logged",
    data: function(){
      return{
        error: "" as string,
      }
    },
    async created(){
      this.error = "";

      await store.dispatch("userStore/Logged");

      const error = (store.state as any).userStore.error

      if(error)
      {
        this.error = error;
        return;
      }
    }
});
