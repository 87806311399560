import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66790d3f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2499.9888145811447!2d4.446857451118648!3d51.20085777948569!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3f769b4eac457%3A0x3a4d52851e422c2c!2sGarage%20De%20Vuyst!5e0!3m2!1sen!2sbe!4v1662360557982!5m2!1sen!2sbe",
  id: "map_canvas",
  style: {"border":"0"},
  allowfullscreen: "true",
  loading: "lazy",
  referrerpolicy: "no-referrer-when-downgrade"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1))
}