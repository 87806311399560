
import { IResult } from "../../../BLL/Tumi/Models/Models";
import { defineComponent } from "vue";
import store from "../../../BLL/store";
import DynamicPage from "../../../BLL/Tumi/Components/DynamicPage.vue";
import { validateEmail } from "../../../BLL/helpers/fonctions"

export default defineComponent({
    name: "Form",
    components:{
      DynamicPage
    },
    props:['text'],
    data:function(){
      return{
        model: null as any|null,
        validate: "" as string,
        pass: "" as string
      }
    },    
    methods:{
      async Save(model: IResult){
        this.validate = "";
        this.pass = "";

        if(model.errors.length != 0 && model.errors.find(i=>i.error == "required")){
          this.Required();
          return;
        }

        if(!validateEmail(model.object.AFSPRAAK_EMAIL)){
          this.ErrorEmail();
          return;
        }

        store.dispatch('dynamicStore/setDynamic',{pathSet: "/afspraak/CreateAfspraak", retour:model.object})
        this.pass = "Bericht doorgestuurd.";
        setTimeout(() => {this.pass = ""}, 3000);
      },
      Required(){
        this.validate = "Alle velden zijn verplicht.";
      },
      ErrorEmail(){
        this.validate = "Gelieve een geldige email in te vullen.";
      }
    },
    async created(){
      await store.dispatch('dynamicStore/getDynamic', "/afspraak/GetEmptyAfspraak");
      this.model = (store.state as any).dynamicStore["/afspraak/GetEmptyAfspraak"];
    }
});
