import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6067fcee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "TumiMobileMenu"
}
const _hoisted_2 = { class: "TumiClose text-end" }
const _hoisted_3 = { class: "TumiRows pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "mobile" }, {
    default: _withCtx(() => [
      (_ctx.showMobileMenu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "TumiMobileMenu",
              style: _normalizeStyle({
          'background-image': 'url(' + require('@/assets/ligne.png') + ')', 
          'background-repeat':'no-repeat'})
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("a", {
                  href: "javascript:void();",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMobileMenu = false))
                }, "x")
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    href: "javascript:void();",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Scroll(1)))
                  }, "Over ons")
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    href: "javascript:void();",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Scroll(2)))
                  }, "Wagen samen stellen")
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    href: "javascript:void();",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Scroll(3)))
                  }, "Afspraak maken")
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    href: "javascript:void();",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Scroll(4)))
                  }, "Ons vinden")
                ])
              ])
            ], 4)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}