
import { defineComponent } from "vue";

export default defineComponent({
    name: "Map",
    data:function(){
      return{
      }
    }
});
