import { apiErrorCheck } from '../../../BLL/helpers/handleResponse'
import { Config } from '../../Config'
import { authHeader } from '../../../BLL/helpers/auth-header'

export const dynamicService = {
    getDynamic,
    setDynamic
};

async function getDynamic(path: string) {
    const headers : HeadersInit = new Headers();
    headers.set('Content-Type', 'application/json')

    const requestOptions = {
        method: 'GET',
        headers: authHeader(headers)
    };

    return apiErrorCheck(Config.Url() + path, requestOptions)
}

async function setDynamic(path: string, obj: any) {
    const headers : HeadersInit = new Headers();
    headers.set('Content-Type', 'application/json')

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: authHeader(headers)
    };

    return apiErrorCheck(Config.Url() + path, requestOptions)
}