
import { defineComponent } from "vue";
import PopUp from '../components/Home/PopUp.vue'
import Menu from '../components/Home/Menu.vue'
import MobileMenu from '../components/Home/MobileMenu.vue'
import Header from '../components/Home/Header.vue'
import Cookies from '../components/Home/Cookies.vue'

import {IContent} from '../../BLL/models/Content/content'
import store from '../../BLL/store/index'
import {Config} from '../../Config'

export default defineComponent({
    name: "Home",
    components:{
      PopUp,
      Menu,
      MobileMenu,
      Header,
      Cookies
    },
    data:function(){
      return{
        content: null as IContent|null
      }
    },
    methods:{
      ToggleMenu(){
        (this.$refs['popup'] as any).Show();
      },
      ToggleMobileMenu(){
        (this.$refs['mobileMenu'] as any).showMobileMenu = true;
      },
      async GetContent(){
        await store.dispatch('dynamicStore/getDynamic', Config.GetContent());
        this.content = (store.state as any).dynamicStore[Config.GetContent()];
      }
    },
    created(){
      this.GetContent();
    }
});

