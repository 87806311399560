
import { defineComponent } from "vue";
import {IContent} from '../../../BLL/models/Content/content'

export default defineComponent({
    name: "PopUp",
    props:['propContent'],
    data:function(){
      return{
        showPopUp: false,
        content: null as IContent|null
      }
    },
    methods:{
      Show(){
        this.showPopUp = !this.showPopUp
      },      
      Scroll(id: number){
        this.Show();
        var target = null;

        switch(id){
          case 1:
            target = document.getElementById('OO')
            break;

          case 2:
            target = document.getElementById('WS')
            break;
          
          case 3:
            target = document.getElementById('FO')
            break;

          case 4:
            target = document.getElementById('RO')
            break;
        }

        const yOffset = -66; 
        const y = (target as any).getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
      }
    },
    watch:{
      propContent(value){
        this.content = value;
      }
    }
});
